import { Link } from "gatsby";
import React from "react";
import { config } from "../../../config";
import Helmet from "../../components/Helmet";
import useScript from "../../hooks/useScripts";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getSeoKeywords } from "../../shared/helpers";
import { checkedCircleIcon, creditCardIcon } from "../../shared/icons";
import OrderDescription from "../Account_create_page/components/OrderDescription";
import TotalOrder from "../Account_create_page/components/TotalOrder";
import styles from "./AccountCreateSuccess.module.scss";
// membership, stepsSnapshot

// uncomment code when we have data
const AccountCreateSuccess = (props: any) => {
    const { response, membership, billing } = props.location.state;
    const last4Characters = billing.card_number?.value.substr(-4);
    const maskingCharacters = billing.card_number?.value.substr(0, billing.card_number.value.length - 4).replace(/\d/g, '*');
    const maskCreditCardNumber = maskingCharacters + last4Characters;

    React.useEffect(() => {
        // TODO: Handle redirect
        // if (!props.location.state?.membership) {
        //     props.navigate("/join-us/");
        //     window.location.reload();
        // }
    }, []);

    const isDesktop = useWindowWidth(576);
    let isLoadScript = useScript(
        "https://js.adsrvr.org/up_loader.1.1.0.js"
      );
  
    return (
        <>
            <Helmet 
                title="Success!"
                description="Success page"
            />
            <div className={styles.successWrapper}>
                {props.location.state?.membership ? (
                    <>
                        <div className={styles.centerHeader}>
                            {checkedCircleIcon}
                            <h1 className={styles.title}>Thanks for joining us!</h1>
                            <div className={styles.information}>
                                <p>
                                    {response.message}
                                </p>
                                <p className={styles.memberId}>Your Member ID: {response.roleId}</p>
                            </div>
                        </div>
                        <div>
                            <OrderDescription
                                membership={membership}
                                additionalClass={styles.onSuccess}
                            />

                            <div className={styles.paymentContainer}>
                                <h4 className={styles.paymentTitle}>{!isDesktop && <span> {creditCardIcon} </span>}Payment</h4>
                                {!isDesktop && billing.card_name?.value && <p className={styles.nameContainer}>{billing.card_name?.value}</p>}
                                <div className={styles.cardInfoContainer}>
                                    {isDesktop && creditCardIcon}
                                    {isDesktop && billing.card_name?.value && <p>{billing.card_name?.value}</p>}
                                    {billing.card_number?.value && <p>{maskCreditCardNumber}</p>}
                                    {billing.card_expiration?.value && <p> Exp. {billing.card_expiration?.value}</p>}
                                </div>
                            </div>
                            <TotalOrder dueToday={membership.dueToday} />
                        </div>
                    </>
                ) : null}
                <Link to={'/'} className={styles.backHomeBtn}>Back to home</Link>
            </div>
        </>
    );
};

export default AccountCreateSuccess;
